import { combineReducers } from 'redux'
import loginReducer from './Login/login.reducer'
import calenderReducer from './Calender/calender.reducer'
import insuranceReducer from './Insurance/insurance.reducer'
import videoReducer from './Video/video.reducer'
import checkoutReducer from './Checkout/checkout.reducer'
import paymentReducer from './Payment/payment.reducer'
import accountsReducer from './Accounts/accounts.reducer'
import accountsTableReducer from './AccountsTable/accountsTable.reducer'
import reportReducer from './Reports/reports.reducer'
import settingsReducer from './Settings/settings.reducer'
import chatReducer from './Chat/chat.reducer'
import reviewsReducer from './Reviews/reviews.reducer'
import appointmentsReducer from './Appointments/appointments.reducer'
import adminReducer from './Admin/admin.reducer'
import regReducer from './PriorAuth/PriorAuthApis/prior.reducer'
import aptReducer from './PriorAuth/Appointment/apt.reducer'
import DashboardReducer from './ProductionDetailed/Dashboard/Dashboard.reducer'
import ProductionReducer from './ProductionDetailed/Production/Production.reducer'
import CollectionReducer from './ProductionDetailed/Collection/Collection.reducer'
import BankReconReducer from './ProductionDetailed/BankRecon/BankRecon.reducer'

/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */

const rootReducer = combineReducers({
  login: loginReducer,
  calender: calenderReducer,
  insurance: insuranceReducer,
  checkout: checkoutReducer,
  payment: paymentReducer,
  accounts: accountsReducer,
  accountsTable: accountsTableReducer,
  Reports: reportReducer,
  settings: settingsReducer,
  video: videoReducer,
  chat: chatReducer,
  reviews: reviewsReducer,
  appointments: appointmentsReducer,
  admin: adminReducer,
  priorAuth: regReducer,
  Appointment: aptReducer,
  dashboard: DashboardReducer,
  production: ProductionReducer,
  collection: CollectionReducer,
  bankRecon:BankReconReducer
})

export default rootReducer
