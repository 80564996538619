import React from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from 'Utils/private-route'
import Loader from './Components/loader'
import Header from 'Components/Header'
const Login = React.lazy(() => import('./Containers/Login'))
const FrontDesk = React.lazy(() => import('./Containers/Dashboard/FrontDesk'))
const ChatTab = React.lazy(() => import('./Chat/ChatTab'))
const Settings = React.lazy(() => import('./Containers/Settings'))
// const AppointmentsTab = React.lazy(() => import('./Containers/AppointmentsTab'))
const AppointmentsTab = React.lazy(() => import('./Containers/ApptTab'))
const Report = React.lazy(() => import('./Containers/Reports'))
const Reviews = React.lazy(() => import('./Containers/Reviews'))
const AccountsModule = React.lazy(() => import('./Containers/AccountsModule'))
const Appointment = React.lazy(() =>
  import('./Components/Reports/AppointmentList'),
)
const Accounts = React.lazy(() => import('./Containers/Accounts'))
const VideoChat = React.lazy(() => import('./VideoComponents/VideoChat'))
const Page404 = React.lazy(() => import('./Containers/Pages/page404'))
const Admin = React.lazy(() => import('./Containers/Admin'))
const PriorAuthTable = React.lazy(() =>
  import('./Containers/PriorAuthModule/DataTableContainer'),
)
const PriorAuthDetails = React.lazy(() =>
  import('./Containers/PriorAuthModule/EditContainer'),
)
const ProductionDetailed = React.lazy(() => import('./Containers/ClaimsModule'))

/**
 * @fileOverview React Base App Component,Routing and Notification section declared here
 * @component
 * @author  Akhil Francis <akhil@tensaw.email>
 * @author Ridhik Govind<ridhik@tensaw.email>
 * @example
 * return(
 * <App/>
 * )
 */

function App() {
  let pathName = window.location.href.split('/')
  return (
    <div className="app__mainContainer">
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        {pathName[3] === 'login' || pathName[3] === '' ? null : <Header />}
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route
              path="/pagenotfound"
              name="/pagenotfound"
              component={Page404}
            />
            <PrivateRoute
              authed={true}
              path="/video/:id"
              name="/Video"
              component={VideoChat}
            />
            <Route
              path="/accounts/patient-list"
              name="/accountspatient-list"
              render={(props) => <Accounts {...props} />}
            />
            <Route
              path="/accounts/patient-details/"
              name="/accountspatient-details/"
              render={(props) => <AccountsModule {...props} />}
            />
            <Route
              path="/reports/appointment"
              name="/reportsappointment"
              render={(props) => <Appointment {...props} />}
            />
            <PrivateRoute
              authed={true}
              path="/reviews"
              name="reviews"
              component={Reviews}
            />
            <PrivateRoute
              authed={true}
              path="/settings"
              name="settings"
              component={Settings}
            />
            <Route
              path="/appointments"
              name="appointments"
              render={(props) => <AppointmentsTab {...props} />}
            />
            <PrivateRoute
              authed={true}
              path="/reports"
              name="reports"
              component={Report}
            />
            <Route
              path="/login"
              name="login"
              render={(props) => <Login {...props} />}
            />
            <PrivateRoute
              authed={true}
              path="/chat"
              name="chat"
              component={ChatTab}
            />
            <PrivateRoute
              path="/dashboard"
              name="Home"
              authed={true}
              component={FrontDesk}
            />
            <PrivateRoute
              path="/admin"
              name="Home"
              authed={true}
              component={Admin}
            />
            <Route
              path="/authorizations/list"
              name="authorizations"
              render={(props) => <PriorAuthTable {...props} />}
            />
            <Route
              path="/authorizations/details"
              name="authorizations"
              render={(props) => <PriorAuthDetails {...props} />}
            />
            <Route
              path="/claims"
              name="claims"
              render={(props) => <ProductionDetailed {...props} />}
            />

            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App
