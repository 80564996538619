import React, { useEffect, useState, useRef } from 'react'
import { socket, chatSocket } from 'Utils/web-socket'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutApi } from 'Redux/Login/login.actions'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import toast from 'react-hot-toast'
import './style.css'

import environment from 'environments/environment'
const systemName = environment.systemName

/**
 * @fileOverview Manages Routing.
 * @component
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @author Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <DefaultLayout/>
 * )
 */

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '2rem !important',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}))

function Header(props) {
  let administratorAccess =
    JSON.parse(localStorage.getItem('isAdministratorAccess')) || false
  const classes = useStyles()
  const history = useHistory()
  const [chatCount, setChatCount] = useState(0)
  const [messagesId, setMessagesId] = useState([])
  const [userData] = useState(JSON.parse(localStorage.getItem('user_data')))
  const [newMenuName, setNewMenuName] = useState('')
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true)
  }

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false)
  }

  /* state for 'upgrade plan' modal */
  // const [openModal, setOpenModal] = useState(false)
  // const handleOpenModal = () => {
  //   setOpenModal(true)
  // }
  // const handleCloseModal = () => {
  //   setOpenModal(false)
  // }
  /******************************* */

  useEffect(() => {
    let value = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
    )
    if (value === 'list') {
      setNewMenuName('authorizations')
    } else if (value === 'patient-list' || value === 'patient-details') {
      setNewMenuName('accounts')
    } else {
      setNewMenuName(value)
    }
  }, [newMenuName])

  useEffect(() => {
    document.addEventListener('DOMContentLoaded', function () {
      if (!Notification) {
        alert(
          'Desktop notifications not available in your browser. Try Chromium.',
        )
        return
      }

      if (Notification.permission !== 'granted')
        Notification.requestPermission()
    })
  }, [])

  useEffect(() => {
    chatSocket.removeListener('user_conversation', messageHandler)
    chatSocket.on('user_conversation', messageHandler)
    return () => {
      chatSocket.removeListener('user_conversation', messageHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCount])

  const messageHandler = (data) => {
    if (data.fromUserId !== userData.userId) {
      new Notification(`${data.fromUserName}`, {
        body: `${data.message}`,
      })
      chatSocket.emit('user_message_status', {
        fromUserType:
          userData.roleId === 2
            ? 'FRONT_DESK'
            : userData.roleId === 3
            ? 'NURSE'
            : userData.roleId === 4
            ? 'PHYSICIAN'
            : null,
        messageStatus: 1,
        conversationId: data.conversationId,
      })
      if (window.location.href.split('/').pop() !== 'chat') {
        toast.success('New Message Recieved', {
          duration: 6000,
        })
        if (messagesId.find((x) => x === data.conversationSessionId)) {
        } else {
          let c = chatCount
          messagesId.push(data.conversationSessionId)
          setMessagesId([...messagesId])
          c = c + 1
          setChatCount(c)
        }
      }
    }
  }

  const redirectToMenuItems = (menuName) => {
    if (menuName === 'dashboard') {
      history.push('/dashboard')
    }
    if (menuName === 'settings') {
      history.push('/settings')
    }
    if (menuName === 'chat') {
      history.push('/chat')
    }
    if (menuName === 'authorizations') {
      history.push('/authorizations/list')
    }
    if (menuName === 'claims') {
      history.push('/claims')
    }
    if (menuName === 'appointments') {
      history.push('/appointments')
    }
    if (menuName === 'reviews') {
      history.push('/reviews')
    }
    if (menuName === 'accounts') {
      history.push('/accounts/patient-list')
    }
    if (menuName === 'reports') {
      // console.log('id', clinicId)
      // if (clinicId === 93022) {
      //   console.log('auth')
      history.push('/reports/authorization')
      // } else {
      //   console.log('dash')
      //   history.push('/reports/dashboard')
      // }
    }
    if (menuName === 'admin') {
      history.push('/admin')
    }
  }

  useEffect(() => {
    if (
      props.LoginData &&
      props.LoginData.logout &&
      props.LoginData.logout.response &&
      props.LoginData.logout.response.data
    ) {
      const { data } = props.LoginData.logout.response
      if (data) window.location.href = '/login'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.LoginData, history])

  const logout = () => {
    socket.emit('clinic_logout', {
      clinicId: userData.clinicId,
      userId: userData.userId,
    })
    chatSocket.emit('clinic_logoff', {
      roleId: userData.roleId,
      userId: userData.userId,
      clinicId: userData.clinicId,
      userName: userData.firstName,
    })
    chatSocket.disconnect()
    props.logoutApi(localStorage.getItem('refreshToken'))
  }

  useEffect(() => {
    if (props.chatData && props.chatData.inboxHistory) {
      if (props.chatData.inboxHistory) {
        let count = 0
        props.chatData.inboxHistory.filteredChatMessages.forEach((x) => {
          if (x.lastMessageStatus !== 2 && x.fromUserId !== userData.userId) {
            messagesId.push(x.conversationSessionId)
            setMessagesId([...messagesId])
            count = count + 1
          }
        })
        setChatCount(count)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatData.inboxHistory])

  //finding FirstName Firstletter and LastName last letter
  const nameLetters = (x) => {
    if (x.split(' ')[1]) {
      return x.charAt(0) + x.split(' ')[1].charAt(0)
    } else {
      return x.charAt(0)
    }
  }

  useEffect(() => {
    if (props.chatData && props.chatData.messageCount) {
      if (props.chatData.messageCount) {
        let count = 0
        props.chatData.messageCount.forEach((x) => {
          if (x.lastMessageStatus !== 2 && x.fromUserId !== userData.userId) {
            count = count + 1
          }
        })
        setChatCount(count)
        if (count === 0) {
          setMessagesId([])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatData.messageCount])

  return (
    <div className="clinic__header">
      <div className="clinic__header--left">
        <ul>
          <li
            className={newMenuName === 'dashboard' ? 'selected' : null}
            onClick={() => {
              redirectToMenuItems('dashboard')
              setNewMenuName('')
            }}
          >
            Scheduler
          </li>
          <li
            className={newMenuName === 'chat' ? 'selected' : null}
            onClick={() => {
              redirectToMenuItems('chat')
              setNewMenuName(' ')
            }}
          >
            Chat
            {chatCount !== 0 && (
              <div className="chat__unreadIndicator">{chatCount}</div>
            )}
          </li>
          <li
            className={newMenuName === 'authorizations' ? 'selected' : null}
            onClick={() => {
              redirectToMenuItems('authorizations')
              setNewMenuName(' ')
            }}
          >
            Authorizations
          </li>
          {systemName === 'System dev' && (
            <li
              className={newMenuName === 'claims' ? 'selected' : null}
              onClick={() => {
                redirectToMenuItems('claims')
                setNewMenuName(' ')
              }}
            >
              Claims
            </li>
          )}
          <li
            className={newMenuName === 'appointments' ? 'selected' : null}
            onClick={() => {
              redirectToMenuItems('appointments')
              setNewMenuName(' ')
            }}
          >
            Appointments
          </li>
          <li
            className={newMenuName === 'reviews' ? 'selected' : null}
            onClick={() => {
              redirectToMenuItems('reviews')
              setNewMenuName(' ')
            }}
          >
            Reviews
          </li>
          <li
            className={newMenuName === 'accounts' ? 'selected' : null}
            onClick={() => {
              redirectToMenuItems('accounts')
              setNewMenuName(' ')
            }}
          >
            Accounts
          </li>
          {administratorAccess && (
            <li
              className={newMenuName === 'authorization' ? 'selected' : null}
              onClick={() => {
                redirectToMenuItems('reports')
                setNewMenuName(' ')
              }}
            >
              Reports
            </li>
          )}
          {administratorAccess && (
            <li
              className={newMenuName === 'admin' ? 'selected' : null}
              onClick={() => {
                redirectToMenuItems('admin')
                setNewMenuName(' ')
              }}
            >
              Admin
            </li>
          )}
        </ul>
      </div>
      <div
        className="clinic__header--left"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        {/* <div
          className="upgrade_button_wrapper"
          onClick={() => {
            handleOpenModal()
          }}
        >
          <Button variant="contained" color="primary">
            {' '}
            Upgrade your account{' '}
          </Button>
        </div> */}
        <div
          className="clinic__profileCircle"
          ref={popoverAnchor}
          aria-owns="mouse-over-popover"
          aria-haspopup="true"
        >
          <span>
            {nameLetters(
              JSON.parse(localStorage.getItem('user_data')).clinicName,
            )}
          </span>
        </div>
        <KeyboardArrowDownIcon color="primary" />
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
      >
        <div className="clinicHeader__popover">
          <div className="clinicHeader__popover--name">
            {JSON.parse(localStorage.getItem('user_data')).clinicName}
          </div>
          <div className="clinicHeader__popover--list">
            <div
              onClick={() => {
                redirectToMenuItems('settings')
              }}
            >
              Settings
            </div>
            <div className="logout" onClick={logout}>
              Logout
            </div>
          </div>
        </div>
      </Popover>
      {/* <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        className="addLocation__dialog"
      >
        <DialogContent className="changePlan_modal">
          <Plans handleCloseModal={handleCloseModal} closeEdit={() => null} />
        </DialogContent>
      </Dialog> */}
    </div>
  )
}
const mapStateToProps = (state) => ({
  LoginData: state.login,
  chatData: state.chat,
})

const mapDispatchToProps = (dispatch) => ({
  logoutApi: (values) => dispatch(logoutApi(values)),
})
Header.propTypes = {
  logoutApi: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)

// ******** DELETED AND UNUSED CODES **********************

// style={pathName[3] === 'settings' ? null : { color: '#828282' }}
// className={pathName[3] === 'settings' ? 'page__selected' : null}

// if (menuName === 'accounts') {
//   if (value !== 'patient-list') {
//     history.push('/accounts/patient-list')
//   }
// }

// if (menuName === 'reports') {
//   if (value !== 'appointment-list') {
//     history.push('/reports/appointment-list')
//   }
// }

// ******************************************************************
