import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import { Apis } from "Redux/APIs";
// import environment from "../../environments/environment";
import environment from "../../../environments/environment";

import {
  REQUEST_CANCEL,
  COL_GROUP_BY_PROVIDER,
  COL_GROUP_BY_SERVICE,
  COLLECTION_DETAILED,
  COL_GROUP_BY_FACILITY,
} from "./Collection.types";

import {
  getColGroupByFacilityRes,
  getColGroupByProviderRes,
  getColGroupByServiceRes,
  getCollectionDetailedRes,
} from "./Collection.actions";

const baseUrl = environment.baseUrl;
const CollectionEpic = {};

CollectionEpic.getColGroupByProvider = (action$) =>
  action$.pipe(
    ofType(COL_GROUP_BY_PROVIDER),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/collection/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getColGroupByProviderRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

CollectionEpic.getColGroupByService = (action$) =>
  action$.pipe(
    ofType(COL_GROUP_BY_SERVICE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/collection/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getColGroupByServiceRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

CollectionEpic.getColGroupByFacility = (action$) =>
  action$.pipe(
    ofType(COL_GROUP_BY_FACILITY),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-clinic-service/v1/collection/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getColGroupByFacilityRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

CollectionEpic.getCollectionDetailed = (action$) =>
  action$.pipe(
    ofType(COLLECTION_DETAILED),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "Content-Type": "application/json",
        },
        url: `${baseUrl}/${Apis.Collection_getCollectionDetailed}/${action.payload.iclinicId}?offset=${action.payload.offset}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getCollectionDetailedRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default CollectionEpic;
