import {
  CREATE_APPOINTMENT,
  GET_CAL_LIST,
  CREATE_APPOINTMENT_RESPONSE,
  GET_CAL_LIST_RESPONSE,
  SEARCH_PATIENT,
  SEARCH_PATIENT_RESPONSE,
  API_STATUS,
  GET_PATIENT_BY_ID,
  GET_PATIENT_BY_ID_DIALOG,
  GET_PATIENT_BY_ID_RESPONSE,
  GET_PATIENT_BY_ID_DIALOG_RESPONSE,
  GET_PATIENT_BY_APT_ID,
  GET_PATIENT_BY_API_ID_RESPONSE,
  UPDATE_APT_DETAILS,
  UPDATE_APT_DETAILS_RESPONSE,
  GET_SEARCH_PATIENT_BY_APT_ID,
  GET_SEARCH_PATIENT_BY_APT_ID_RESPONSE,
  IS_CHECKIN_STATUS,
  SHOW_ACCORDION,
  ZIP_SEARCH,
  ZIP_SEARCH_RES,
  ZIP_SEARCH_PAYER_ADDRESS,
  ZIP_SEARCH_PAYER_ADDRESS_RES,
  ZIP_SAVE_POLICY_HOLDER,
  ZIP_SAVE_POLICY_HOLDER_RES,
  ZIP_SEARCH_ACCORDIAN_ADDRESS,
  ZIP_SEARCH_ACCORDIAN_ADDRESS_RES,
  DRAG_APPOINTMENT,
  DRAG_APPOINTMENT_RESPONSE,
  APPOINTMENT_DATA,
  APPOINTMENT_DATA_RESPONSE,
  DELETE_APPOINTMENT,
  DELETE_APPOINTMENT_RES,
  IS_ACCORDION_OPEN,
  SCREEN_WIDTH,
  PUSH_POLICYHOLDER,
  EDIT_VIEW_STATUS,
  CLEAR_APPOINTMENT,
  SAVE_CURRENT_PATIENT_ID,
  CANCEL_APPOINTMENT,
  CANCEL_APPOINTMENT_RES,
  CANCEL_POPOVER_STATUS,
  APOINTMENT_INFO_INSURANCE,
  APOINTMENT_INFO_INSURANCE_RES,
  TRIGGER_APPT_REMINDER_REVIEW,
  TRIGGER_APPT_REMINDER_REVIEW_RES,
} from './calender.types'

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * return(
    type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from calender.type.js,
  payload: object - contains params,
})
 */

/**
 * newAptSubmit -Action  w.r.t  CREATE_APPOINTMENT(type) in the redux
 * @description - Action used to call the create appointment save API of a follow up  patient
 * @param appointmentData - {
      clinicId,
      patientId,
      consultationType,
      appointmentTypeId,
      providerId,
      locationId,
      startDate,
      startTime,
      endTime,
      reason,
      notes,
 }
 * @return {{payload: {}, type: string}}
 */
export const newAptSubmit = (appointmentData) => ({
  type: CREATE_APPOINTMENT,
  payload: appointmentData,
})

/**
 *
 * CreateAppointmentResponse -Action  w.r.t  CREATE_APPOINTMENT_RESPONSE(type) in the redux
 * @description - Action used to get the success response of the create appointment save data of a follow up  patient
 * @param data
 * @return {{payload: {}, type: string}}
 */
export const CreateAppointmentResponse = (data) => ({
  type: CREATE_APPOINTMENT_RESPONSE,
  payload: data,
})

/**
 * GetCalenderList -Action  w.r.t  GET_CAL_LIST(type) in the redux
 * @description - Action used to call the calender appointment details to display in full calender
 * @param calenderList - {
      clinicId,
      providerId,
    }
 * @return {{payload: {}, type: string}}
 */
export const GetCalenderList = (calenderList) => ({
  type: GET_CAL_LIST,
  payload: calenderList,
})

/**
 * GetCalenderlistResponse -Action  w.r.t  GET_CAL_LIST_RESPONSE(type) in the redux
 * @description - Action used to get the success response of the calender appointment Response to display in full calender
 * @param data
 * @return {{payload: {}, type: string}}
 */
export const GetCalenderlistResponse = (data) => ({
  type: GET_CAL_LIST_RESPONSE,
  payload: data,
})

/**
 * SearchPatient -Action  w.r.t  SEARCH_PATIENT(type) in the redux
 * @description - Action used to call the search a patient API in the create appointment form
 * @param data - {
      clinicId,
      searchFirstName,
      searchLastName,
      searchDob,

 * @return {{payload: {}, type: string}}
 */
export const SearchPatient = (data) => ({
  type: SEARCH_PATIENT,
  payload: data,
})
/**
             * SearchPatientResponse -Action  w.r.t  SEARCH_PATIENT_RESPONSE(type) in the redux
             * @description - Action used to get  the success response of  a search patient  in the create appointment form to display in table row
             * @param data
             * return(
             type and payload
             * )
             */
export const SearchPatientResponse = (data) => ({
  type: SEARCH_PATIENT_RESPONSE,
  payload: data,
})
/**
             * apiStatus -Action  w.r.t  API_STATUS(type) in the redux
             * @description - Action used to call a status as a boolean value to reload the get calender list API
             * @param apistatus: boolean
             * return(
             type and payload
             * )
             */
export const apiStatus = (apistatus) => ({
  type: API_STATUS,
  payload: apistatus,
})

/**
 * GetPatientById -Action  w.r.t  GET_PATIENT_BY_ID(type) in the redux
 * @description - Action used to call the search patient details when we click on the table row in th create appointment popup
 * @param patient - {patientid}
 * return(
 type and payload
 * )
 */
export const GetPatientById = (patient) => ({
  type: GET_PATIENT_BY_ID,
  payload: patient,
})

export const GetPatientByIdDialog = (patient) => ({
  type: GET_PATIENT_BY_ID_DIALOG,
  payload: patient,
})
/**
 * GetPatientByIdResponse -Action  w.r.t  GetPatientByIdResponse(type) in the redux
 * @description - Action used to get  the search patient details when we click on the table row in th create appointment popup
 * @param data
 * return(
 type and payload
 * )
 * payload - {
      clinicId,
      lastName,
      firstName,
      middleName,
      dob,
      sex,
      maritalStatus,
      addressLine1,
      addressLine2,
      cellPhone,
      email,
      mrn,
      insuranceType,
      city,
      state,
      ssn,
      zip,
      pin,
      country,
      billingMethod}
 */
export const GetPatientByIdResponse = (data) => ({
  type: GET_PATIENT_BY_ID_RESPONSE,
  payload: data,
})

export const GetPatientByIdDialogResponse = (data) => ({
  type: GET_PATIENT_BY_ID_DIALOG_RESPONSE,
  payload: data,
})
/**
             * GetPatientByAptId -Action  w.r.t  GET_PATIENT_BY_APT_ID(type) in the redux
             * @description - Action used to call the patient details by appointmentId
             * @param patient - {
              appointmentId
             * }
             * return(
             type and payload
             * )
             */

export const GetPatientByAptId = (patient) => ({
  type: GET_PATIENT_BY_APT_ID,
  payload: patient,
})
/**
             * GetPatientByAptIdResponse -Action  w.r.t  GET_PATIENT_BY_API_ID_RESPONSE(type) in the redux
             * @description - Action used to get the response of the patient details by appointmentId
             * @param data
             * return(
             type and payload
             * )
             * payload - {
             *   clinicId,
                  lastName,
                  firstName,
                  middleName,
                  dob,
                  sex,
                  maritalStatus,
                  addressLine1,
                  addressLine2,
                  cellPhone,
                  email,
                  mrn,
                  insuranceType,
                  city,
                  state,
                  ssn,
                  zip,
                  pin,
                  country,
                  billingMethod}
             */
export const GetPatientByAptIdResponse = (data) => ({
  type: GET_PATIENT_BY_API_ID_RESPONSE,
  payload: data,
})
/**
             * UpdateAptDetails - Action  w.r.t  UPDATE_APT_DETAILS(type) in the redux
             * @description - Action used to update the patient details
             * @param data - {
                  clinicId,
                  lastName,
                  firstName,
                  middleName,
                  dob,
                  sex,
                  maritalStatus,
                  addressLine1,
                  addressLine2,
                  cellPhone,
                  email,
                  mrn,
                  insuranceType,
                  city,
                  state,
                  ssn,
                  zip,
                  pin,
                  country,
                  billingMethod,
                    patientId,
                    consultationType,
                    appointmentTypeId,
                    providerId,
                    locationId,
                    startDate,
                    startTime,
                    endTime,
                    reason,
                    notes,

                }
             * return(
             type and payload
             * )
             */
export const UpdateAptDetails = (data) => ({
  type: UPDATE_APT_DETAILS,
  payload: data,
})
/**
             * UpdateAptDetailsResponse - Action  w.r.t  UPDATE_APT_DETAILS_RESPONSE(type) in the redux
             * @description - Action used to get the response of the patient details
             * @param data
             * return(
             type and payload
             * )
             */
export const UpdateAptDetailsResponse = (data) => ({
  type: UPDATE_APT_DETAILS_RESPONSE,
  payload: data,
})

/**
 * GetSearchPatientByAptId - Action  w.r.t  GET_SEARCH_PATIENT_BY_APT_ID(type) in the redux
 * @description - Action used to call the  patient details in checkin by appointment id
 * @param data - {Appointment id}
 * return(
 type and payload
 * )
 */
export const GetSearchPatientByAptId = (data) => ({
  type: GET_SEARCH_PATIENT_BY_APT_ID,
  payload: data,
})
/**
             * GetSearchPatientByAptIdResponse - Action  w.r.t  GET_SEARCH_PATIENT_BY_APT_ID_RESPONSE(type) in the redux
             * @description - Action used to get the response of the patient details by appointment in checkin
             * @param data -  {
             *   clinicId,
                  lastName,
                  firstName,
                  middleName,
                  dob,
                  sex,
                  maritalStatus,
                  addressLine1,
                  addressLine2,
                  cellPhone,
                  email,
                  mrn,
                  insuranceType,
                  city,
                  state,
                  ssn,
                  zip,
                  pin,
                  country,
                  billingMethod}
             * return(
             type and payload
             * )

             */
export const GetSearchPatientByAptIdResponse = (data) => ({
  type: GET_SEARCH_PATIENT_BY_APT_ID_RESPONSE,
  payload: data,
})
/**
             * isCheckinStatus - Action  w.r.t  IS_CHECKIN_STATUS(type) in the redux
             * @description - Action used to call the boolean status from full-calender event click to open checkin section
             * @param isCheckin :boolean
             * return(
             type and payload
             * )
             */
export const isCheckinStatus = (isCheckin) => ({
  type: IS_CHECKIN_STATUS,
  payload: isCheckin,
})
/**
             * showAccordion - Action  w.r.t  SHOW_ACCORDION(type) in the redux
             * @description - Action used to show accordian when we click an event in full calendar
             * @param show :boolean
             * return(
             type and payload
             * )
             */
export const showAccordion = (show) => ({
  type: SHOW_ACCORDION,
  payload: show,
})

/**
 * getAddressFromZip - Action  w.r.t  ZIP_SEARCH(type) in the redux
 * @description - Action used to call api to list city state and country a/c zip code in create appointment popup
 * @param data - {zipcode}
 * return(
 type and payload
 * )
 */
export const getAddressFromZip = (data) => ({
  type: ZIP_SEARCH,
  payload: data,
})
/**
             * getAddressFromZipRes - Action  w.r.t  ZIP_SEARCH_RES(type) in the redux
             * @description - Action used to get response of zip code api to list city state and country a/c zip code in create appointment popup
             * @param data - {
             *   city,
             *   state,
             *   country
             * }
             * return(
             type and payload
             * )
             */
export const getAddressFromZipRes = (data) => ({
  type: ZIP_SEARCH_RES,
  payload: data,
})
/**
             * getAddressFromPayerAddressZip - Action  w.r.t  ZIP_SEARCH_PAYER_ADDRESS(type) in the redux
             * @description - Action used to call api to list city state and country a/c zip code in payer address popup
             * @param data -{zipcode}
             * return(
             type and payload
             * )
             */
export const getAddressFromPayerAddressZip = (data) => ({
  type: ZIP_SEARCH_PAYER_ADDRESS,
  payload: data,
})
/**
             * getAddressFromPayerAddressZipRes - Action  w.r.t  ZIP_SEARCH_PAYER_ADDRESS_RES(type) in the redux
             * @description - Action used to get response of zip code api to list city state and country a/c zip code in payer address popup
             * @param data - {
             *   city,
             *   state,
             *   country
             * }
             * return(
             type and payload
             * )
             */
export const getAddressFromPayerAddressZipRes = (data) => ({
  type: ZIP_SEARCH_PAYER_ADDRESS_RES,
  payload: data,
})
/**
             * getAddressFromAccordianZip - Action  w.r.t  ZIP_SEARCH_ACCORDIAN_ADDRESS(type) in the redux
             * @description - Action used to call api to list city state and country a/c zip code in accordian section
             * @param data - {zipcode}
             * return(
             type and payload
             * )
             */
export const getAddressFromAccordianZip = (data) => ({
  type: ZIP_SEARCH_ACCORDIAN_ADDRESS,
  payload: data,
})
/**
             * getAddressFromAccordianZipRes - Action  w.r.t  ZIP_SEARCH_ACCORDIAN_ADDRESS_RES(type) in the redux
             * @description - Action used to get response of zip code api to list city state and country a/c zip code in accordian section
             * @param data - {
             *   city,
             *   state,
             *   country
             * }
             * return(
             type and payload
             * )
             */
export const getAddressFromAccordianZipRes = (data) => ({
  type: ZIP_SEARCH_ACCORDIAN_ADDRESS_RES,
  payload: data,
})

/**
             * savePolicyHolderZip - Action  w.r.t  ZIP_SAVE_POLICY_HOLDER(type) in the redux
             * @description - Action used to call api to list city state and country a/c zip code in policy holder section
             * @param data - {zipcode}
             * return(
             type and payload
             * )
             */
export const savePolicyHolderZip = (data) => ({
  type: ZIP_SAVE_POLICY_HOLDER,
  payload: data,
})
/**
             * savePolicyHolderZipRes - Action  w.r.t  ZIP_SAVE_POLICY_HOLDER_RES(type) in the redux
             * @description - Action used to get response of zip code api to list city state and country a/c zip code in policy holder section
             * @param data - {
             *   city,
             *   state,
             *   country
             * }
             * return(
             type and payload
             * )
             */
export const savePolicyHolderZipRes = (data) => ({
  type: ZIP_SAVE_POLICY_HOLDER_RES,
  payload: data,
})

/**
 * dragAppointment - Action  w.r.t  DRAG_APPOINTMENT(type) in the redux
 * @description - Action used to call api to drag and drop the events in full calendar
 * @param data
 * return(
 type and payload
 * )
 */
export const dragAppointment = (data) => ({
  type: DRAG_APPOINTMENT,
  payload: data,
})

/**
 * dragAppointmentResponse - Action  w.r.t  DRAG_APPOINTMENT_RESPONSE(type) in the redux
 * @description - Action used to get response of drag and drop the events in full calendar
 * @param data
 * return(
 type and payload
 * )
 */
export const dragAppointmentResponse = (data) => ({
  type: DRAG_APPOINTMENT_RESPONSE,
  payload: data,
})

/**
 * appointmentDrag - Action  w.r.t  APPOINTMENT_DATA(type) in the redux
 * @description - Action used to call the appointment data while drag the event in full calendar
 * @param data
 * return(
 type and payload
 * )
 */
export const appointmentDrag = (data) => ({
  type: APPOINTMENT_DATA,
  payload: data,
})

/**
 * appointmentDataResponse - Action  w.r.t  APPOINTMENT_DATA_RESPONSE(type) in the redux
 * @description - Action used to get response of api to get appointment data while drag the event in full calender
 * @param data
 * return(
 type and payload
 * )
 */
export const appointmentDataResponse = (data) => ({
  type: APPOINTMENT_DATA_RESPONSE,
  payload: data,
})

/**
         * deleteAppointment - Action  w.r.t  DELETE_APPOINTMENT(type) in the redux
         * @description - Action used to delete the appointment in the calender
         * @param deleteId : appointment id
         * return(
         type and payload
         * )
         */
export const deleteAppointment = (deleteId) => ({
  type: DELETE_APPOINTMENT,
  payload: deleteId,
})
/**
         * deleteAppointmentRes - Action  w.r.t  DELETE_APPOINTMENT_RES(type) in the redux
         * @description - Action used to get the response of delete the appointment in the calender
         * @param deleteId : appointment id
         * return(
         type and payload
         * )
         */
export const deleteAppointmentRes = (deleteId) => ({
  type: DELETE_APPOINTMENT_RES,
  payload: deleteId,
})

/**
         * deleteAppointmentRes - Action  w.r.t  DELETE_APPOINTMENT_RES(type) in the redux
         * @description - Action used to get the response of delete the appointment in the calender
         * @param deleteId : appointment id
         * return(
         type and payload
         * )
         */
export const isAccordionOpen = (status) => ({
  type: IS_ACCORDION_OPEN,
  payload: status,
})

/**
     * screenWidth - Action  w.r.t  SCREEN_WIDTH(type) in the redux
     * @description - Action used to get a status from menu to render the calender
     * @param status : boolean
     * return(
     type and payload
     * )
     */
export const screenWidth = (status) => ({
  type: SCREEN_WIDTH,
  payload: status,
})

export const pushPolicyHolder = (value) => ({
  type: PUSH_POLICYHOLDER,
  payload: value,
})
/**
     * editApptViewStatus - Action  w.r.t  EDIT_VIEW_STATUS(type) in the redux
     * @description - Action used to get a status edit appointment view in accordian
     * @param status : boolean
     * return(
     type and payload
     * )
     */
export const editApptViewStatus = (value) => ({
  type: EDIT_VIEW_STATUS,
  payload: value,
})
export const clearAppointment = (value) => ({
  type: CLEAR_APPOINTMENT,
  payload: value,
})
export const saveCurrentPatientID = (value) => ({
  type: SAVE_CURRENT_PATIENT_ID,
  payload: value,
})
export const cancelAppointment = (data) => ({
  type: CANCEL_APPOINTMENT,
  payload: data,
})

export const cancelAppointmentRes = (data) => ({
  type: CANCEL_APPOINTMENT_RES,
  payload: data,
})

export const cancelPopoverRes = (data) => ({
  type: CANCEL_POPOVER_STATUS,
  payload: data,
})

export const getApointmentinfoInsurance = (id) => ({
  type: APOINTMENT_INFO_INSURANCE,
  payload: id,
})
export const getApointmentinfoInsuranceResponse = (res) => ({
  type: APOINTMENT_INFO_INSURANCE_RES,
  payload: res,
})

export const triggerApptReminderReview = (data) => ({
  type: TRIGGER_APPT_REMINDER_REVIEW,
  payload: data,
})

export const triggerApptReminderReviewRes = (res) => ({
  type: TRIGGER_APPT_REMINDER_REVIEW_RES,
  payload: res,
})
